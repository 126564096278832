// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $title-font-family;
$card-title-font        	: bold 1.222rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $title-font-family;
$card-more-link-font			: bold 1.222rem/1 $title-font-family;
$card-feed-link-font			: bold 0.777rem/1.46153846153846 $title-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
//$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
//$card-date-bg-color            : transparent;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $granite_grey;
$card-date-font                : 0.777em/1.5 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color               : $tangerine;
$card-hover-shadow                 : $box-shadow;
$card-action-hover                 : $primary;
$card-text-hover-color             : $secondary;
$card-date-hover-text-color        : $secondary;
$card-date-hover-bg-color          : transparent;
$card-hover-text-info              : $granite_grey;

$card-hover-alt-bg-color           : $electric_blue;
$card-hover-alt-shadow             : $box-shadow;
$card-action-hover-alt             : white;
$card-text-hover-alt-color         : white;
$card-date-hover-alt-text-color    : $secondary;
$card-date-hover-alt-bg-color      : transparent;
$card-hover-alt-text-info          : white;

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $primary;
$card-light-action-default     : $action-default;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary;
$card-light-text-info          : $granite_grey;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $stellar_light;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $action-default;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;
$card-medium-text-info         : $granite_grey;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $pale_grey_two;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $black;
$card-dark-action-default      : $black;
$card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $tangerine;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $primary;
$card-highlight-action-default : $action-default;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $secondary;
$card-highlight-subtitle-color : $secondary;
$card-highlight-text-info      : $granite_grey;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $stellar_light;
$card-more-link-default-color       : $primary;
$card-more-link-default-icon        : $arrow-granite-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : $tangerine;
$card-more-link-default-hover-color : $primary;
$card-more-link-default-hover-icon  : $arrow-granite-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $tangerine;
$card-more-link-alt-color           : $primary;
$card-more-link-alt-icon            : $arrow-granite-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $electric_blue;
$card-more-link-alt-hover-color     : white;
$card-more-link-alt-hover-icon      : $arrow-white-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-2-bg              : $white;
$card-more-link-alt-2-color           : $primary;
$card-more-link-alt-2-icon            : $arrow-granite-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-2-hover-bg        : $tangerine;
$card-more-link-alt-2-hover-color     : $primary;
$card-more-link-alt-2-hover-icon      : $arrow-granite-right no-repeat 100% 100% / 14px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : $electric_blue; // background for the entire link
$card-feed-link-default-color       : white;
$card-feed-link-default-icon        : $electric_blue $arrow-white-right no-repeat scroll 50% 50% / 12px 16px; //background for the default :after component
$card-feed-link-default-hover-bg    : $tangerine ;
$card-feed-link-default-hover-color : $primary;
$card-feed-link-default-hover-icon  : $tangerine $arrow-carbon-right no-repeat scroll 50% 50% / 12px 16px;

$card-feed-link-alt-bg              : $tangerine; // background for the entire link
$card-feed-link-alt-color           : $primary;
$card-feed-link-alt-icon            : $tangerine $arrow-carbon-right no-repeat scroll 50% 50% / 12px 16px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : $stellar_light;
$card-feed-link-alt-hover-color     : $primary;
$card-feed-link-alt-hover-icon      : $stellar_light $arrow-carbon-right no-repeat scroll 50% 50% / 12px 16px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $action-default; 
$button-link-default-border-color       : $action-default;
$button-link-default-color              : white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $action-hover;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : $primary;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $tangerine;
$button-link-alt-border-color           : $tangerine;
$button-link-alt-color                  : $primary;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : white;
$button-link-alt-hover-border-color     : white;
$button-link-alt-hover-color            : $primary;
$button-link-alt-hover-icon             : none;