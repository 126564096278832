.item-list  ul.pager {
	position: relative;
	font-size: 0.888rem;
	a {
		color: $granite_grey !important;
		font-weight: 600;
		
		&:hover {
			color:$tangerine!important;
		}
	}
	li.pager-next {
		width: 30px;
		a {
			display: block;
			background: $arrow-granite-right no-repeat 100% 50% / 20px 16px;
			text-indent: -9999px;
			&:hover {
				background: $arrow-tangerine-right no-repeat 100% 50% / 20px 16px;
			}
		}
	}
	li.pager-item.pager-last {
		float: right;
		a {
			display: block;
			//background: $arrow-right-last;
			padding-right: 20px;
			background-position: 100% 50%;
			background-size: 14px;
		}
	}
	li.pager-previous {
		a {
			display: block;
			background: $arrow-granite-left no-repeat 100% 50% / 20px 16px;
			text-indent: -9999px;
			&:hover {
				background: $arrow-tangerine-left no-repeat 100% 50% / 20px 16px;
			}
		}
	}
	li.pager-item.pager-first {
		a {
			display: block;
			//background: $arrow-left-first;
			padding-left: 20px;
			background-position: 0% 50%;
			background-size: 14px;
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		color: $action-default;
		//color:white;
		// padding:0.25em 0.5em;
		margin-top: 0.25em;
		//border-radius:50%;
		//border-top: 3px solid;
		//border-bottom: 3px solid;
	}
}