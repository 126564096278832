section .section-row.section-with-promo {
  padding: 0;
  width: 100%;
  position: relative;
  line-height: 0;
  .section-inner {
    max-width: none;
    width: 100%;
    padding:0;
  }
}
.view-promo {
  //height: 500px;
  margin: 0 -10px;
  @media(min-width:600px){
    margin: -20px -30px;
    height: 360px;
  }
  .promo {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $ultramarine;
    .card-image {
      width:720px;
      position: relative;
      display: inline-block;
      order:2;
      @media(min-width:600px){
        width: 632px; 
      }
      @media(min-width:1000px) and (max-width: 1279px){
        margin-left: -180px;
      }
      &:after {
        content:"";
        height: 762px;
        display: inline-block;
        background: $promo-bg no-repeat 50% 50%;
        background-size:auto 840px;
        width:840px;
        position: absolute;
        top:-42px;        
        left:-150px;
        @media(min-width:600px) {
          top:-280px;
          left:-134px;
        }
      }
    }
    picture, img {
      max-width: none;
      width: 100%;
      display: inline-block;
      vertical-align: baseline;
    }
    &:hover {
      background: #B3E2E7;
      .card-image:after {
        background: $promo-bg-hover no-repeat 50% 50% / auto 840px;
      }
    }
  }
  .card-promo-text {
    padding: 20px 40px;
    top: 20px;
    color: $carbon!important;
    order:1;
    z-index: 2;
    width: 100%;
    height: 100%;
    @media(min-width: 600px) {
      position: absolute;
      background-color: rgba(128, 206, 215, 0.7);
      top:0;
    }
    @media(min-width:1000px) {
      padding:0 0 0 470px;
      top:20px;
      background-color: transparent;
    }
    @media(min-width:1280px) {
      padding:0 20px 20px 670px;
    }
    .promo-title {
      display: inline-block;
      line-height: 1;
      margin-bottom: 20px;
      font-size: 30px
    } 
    .card-description .promo-description p {
      font-size: 22px;
      line-height: 36px;
    }
  }
}