.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			//float:left;
			margin:0 $horz-space -1px 0;
			min-width:4rem;
			.file-extension {
				padding:8px 10px 7px;
				text-transform:lowercase;
				font-weight: bold;
				font-size:0.888em;
        line-height:1;
				text-align:center;
				font-weight:bold;
				display: inline-block;
			}
			.file-size {
        padding: 4px 10px;
				font-size:0.888em;
				font-weight: bold;			
				text-align:center;
				color:$granite-grey;
        border-right:$secondary-border;
        border-top:none;
				display: inline-block;
				background:white;
			}
		}
		
		.file-name {
			overflow:hidden;
			background: white;
			padding: 20px;
			font-weight: bold;
			font-size: 1.222em;
			color:$primary;
			box-shadow: $box-shadow;
			margin: 0 2px 4px 0;
			
		}
		&:hover {
			.file-name,
			.file-size {
				background:$tangerine;
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
} 

.palette-default {
	.attachment-link {
		.file-name,
		.file-icon .file-size {
			background: $stellar_light;
		}
		&:hover {
			.file-size {
				background:$tangerine;
			}
		}
	}
}

.palette-alt-3 {
	.attachment-link {
		.file-name,
		.file-icon .file-size {
			background: $tangerine;
		}
		&:hover {
			.file-name,
			.file-size {
				background:$electric_blue;
				color:white;
			}
		}
	}
	
}

// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:15px;
		}
		
		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:15px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:15px;
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}
}
