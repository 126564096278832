/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
	
	.faq-question {
		margin-bottom:20px;
	}

	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 40px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		color: $electric_blue;
		padding: 10px 0 10px 40px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand-electric no-repeat scroll 0 50% / 24px 24px transparent;
		font-size: 1.111em;
		&:hover {
			color:$tangerine;
			background: $ico-expand-tangerine no-repeat scroll 0 50% / 24px 24px transparent;
		}

		&.active {
			//font-weight: bold;
			background: $ico-collapse-electric no-repeat scroll 0 50% / 24px 24px transparent;
			&:hover {
				background: $ico-collapse-tangerine no-repeat scroll 0 50% / 24px 24px transparent;
			}
		}
	}
}

.section-row.palette-highlight {
	.view-faqs {
		.card-qa-answer {
			color:white;
		}
		.card-qa-question {
			color: $tangerine;
			background: $ico-expand-tangerine no-repeat scroll 0 50% / 24px 24px transparent;
			&:hover {
				color:white;
				background: $ico-expand-white no-repeat scroll 0 50% / 24px 24px transparent;
			}
			&.active {
				background: $ico-collapse-tangerine no-repeat scroll 0 50% / 24px 24px transparent;
				&:hover {
					background: $ico-collapse-white no-repeat scroll 0 50% / 24px 24px transparent;
				}
			}
		}
	}
}