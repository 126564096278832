@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i');

$base-font-family      		: 'Lato', "Lucida Grande", sans-serif;
$title-font-family    		: 'Lato', Tahoma, serif;
$subtitle-font-family 	 	: 'Lato', Tahoma, serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: 600 0.88rem/1 $base-font-family;

/* FORMS */
$button-font 							: 600 0.888rem/1 $title-font-family;