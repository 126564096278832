fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: none;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 0.777rem;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;
	height:32px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background-color: white;
	width: auto;
	border: none;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 0.777rem;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	height:32px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	
	background: white $arrow-filter no-repeat 100% 50% / 32px 32px;

}

.page-user {
	input[type="text"],
	input[type="password"] {
		border: solid 1px $electric_blue; 
	}
}
