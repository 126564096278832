// /* SECTION/NUGGET ROWS */ /

$section-row-title-font 	: 2rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller 	: 500 2rem/1.25 $title-font-family; // /* Nugget title */ 


$section-row-padding          : $vert-space*2 0;
$section-inner-mobile-padding : 0 $horz-space/2;
$section-inner-padding        : 0 $horz-space;

// /*Default sets the card colours for main and additional also */
$palette-default-bg-color      : white;
$palette-default-title-color   : $primary;
$palette-default-text-color    : $secondary;
$palette-default-link-color	   : $action-default;
$palette-default-link-hover    : $action-hover;

//
// --- Set the card style for the DEFAULT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-default-cards {
	@include card-medium();
}
@mixin palette-default-card-more-link {
	@include card-more-link-default();
}
@mixin palette-default-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-default-button-link {
	@include button-link-default();
}
@mixin palette-default-slick-controls {
	@include slick-controls-alt-1();
}


$palette-alt-1-bg-color        : $winters_day $logo-bg-azure no-repeat 100% 50% / 512px 512px;
$palette-alt-1-title-color     : $primary;
$palette-alt-1-text-color      : $secondary;
$palette-alt-1-link-color      : $action-default;
$palette-alt-1-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-1 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-1-cards {
	@include card-light();
}
@mixin palette-alt-1-card-more-link {
	@include card-more-link-alt-2();
}
@mixin palette-alt-1-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-1-button-link {
	@include button-link-default();
}
@mixin palette-alt-1-slick-controls {
	@include slick-controls-alt-2();
}


$palette-alt-2-bg-color        : $ghost_white;
$palette-alt-2-title-color     : $primary;
$palette-alt-2-text-color      : $secondary;
$palette-alt-2-link-color	     : $action-default;
$palette-alt-2-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-2 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-2-cards {
	@include card-light();
}
@mixin palette-alt-2-card-more-link {
	@include card-more-link-alt-2();
}
@mixin palette-alt-2-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-2-button-link {
	@include button-link-default();
}
@mixin palette-alt-2-slick-controls {
	@include slick-controls-alt-2();
}


$palette-highlight-bg-color    : $mamala_bay;
$palette-highlight-title-color : white;
$palette-highlight-text-color  : white;
$palette-highlight-link-color	 : $tangerine;
$palette-highlight-link-hover  : white;

//
// --- Set the card style for the HIGHLIGHT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-highlight-cards {
	@include card-light();
}
@mixin palette-highlight-card-more-link {
	@include card-more-link-alt-2();
}
@mixin palette-highlight-card-feed-link {
	@include card-feed-link-alt();
}
@mixin palette-highlight-button-link {
	@include button-link-alt();
}
@mixin palette-highlight-slick-controls {
	@include slick-controls-default();
}


$palette-alt-3-bg-color        : white;
$palette-alt-3-title-color     : $primary;
$palette-alt-3-text-color      : $secondary;
$palette-alt-3-link-color	     : $action-default;
$palette-alt-3-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-3 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-3-cards {
	@include card-highlight();
}
@mixin palette-alt-3-card-more-link {
	@include card-more-link-alt();
}
@mixin palette-alt-3-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-3-button-link {
	@include button-link-default();
}
@mixin palette-alt-3-slick-controls {
	@include slick-controls-alt-2();
}