// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: $carbon; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: $arrow-white-left no-repeat scroll 50% 50% / 14px 20px;
$slick-default-prev-hover-bg      				: $arrow-electric-left no-repeat scroll 50% 50% / 14px 20px;
$slick-default-next-bg            				: $arrow-white-right no-repeat scroll 50% 50% / 14px 20px;
$slick-default-next-hover-bg      				: $arrow-electric-right no-repeat scroll 50% 50% / 14px 20px;
$slick-default-dot-border         				: 2px solid white;
$slick-default-dot-bg                     : white;
$slick-default-dot-hover-bg               : $electric_blue;
$slick-default-dot-hover-border    				: 2px solid $electric_blue;
$slick-default-dot-active-bg       				: $tangerine;
$slick-default-dot-active-border          : 2px solid $tangerine;
$slick-default-dot-active-hover-bg-color 	: $electric_blue;


$slick-alt-1-nav-bg                				: $carbon;
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: $arrow-granite-left no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-prev-hover-bg         				: $arrow-tangerine-left no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-next-bg               				: $arrow-granite-right no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-next-hover-bg         				: $arrow-tangerine-right no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-dot-default-border    				: 2px solid $winters_day;
$slick-alt-1-dot-bg                       : $winters_day;
$slick-alt-1-dot-hover-border      				: 2px solid $tangerine;
$slick-alt-1-dot-hover-bg                 : $tangerine;
$slick-alt-1-dot-active-bg-color   				: $electric_blue;
$slick-alt-1-dot-active-border            : 2px solid $electric_blue;
$slick-alt-1-dot-active-hover-bg-color 		: $tangerine;


$slick-alt-2-nav-bg                 			: $carbon; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $arrow-granite-left no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-2-prev-hover-bg         				: $arrow-tangerine-left no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-2-next-bg               				: $arrow-granite-right no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-2-next-hover-bg         				: $arrow-tangerine-right no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-2-dot-default-border    				: 2px solid $granite_grey;
$slick-alt-2-dot-bg                       : $granite_grey;
$slick-alt-2-dot-hover-border      				: 2px solid $tangerine;
$slick-alt-2-dot-hover-bg                 : $tangerine;
$slick-alt-2-dot-active-bg-color   				: $electric_blue;
$slick-alt-2-dot-active-border            : 2px solid $electric_blue;
$slick-alt-2-dot-active-hover-bg-color 		: $tangerine;



// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgba(255, 255, 255, 0.7) ;
$slide-text-color          : $primary;
$slide-text-font           : 1.222rem/1.45 $title-font-family;