// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:22px;
			height:6px;
			margin:5px;
			button {
				display:inline-block;
				width:22px;
				height:6px;
				border-radius:0;
				padding:0;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead 
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 25px;
	}
	.slick-dots {
		bottom:14px;
	}	
}


// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		margin-bottom: 23px;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							// @include media($wide) {
							// 	left:calc(50% - 800px);
							// 	width: auto;
							// }
						}
					}
					
					.card-slide-description {
						bottom:0;
						position:absolute;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:20px 20px 70px 20px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
						text-align: center;
						width:100%;
					
						p {
							font:$slide-text-font;
							font-size: 1rem;
							margin: 0;
						}

						@include media($narrow) {
							border-radius: 50%;
							width:240px;
							height:240px;
							background:$slide-text-bg-color $logo-bg-slider no-repeat 40% 50% / 200px 200px;
							position:absolute;
							padding:50px 35px;
							top:calc(50% - 120px);
							left:20px;
							font-size: 1rem;
						}
						@media(min-width:960px) {
							height:300px;
							width:300px;
							background:$slide-text-bg-color $logo-bg-slider no-repeat 40% 50% / 250px 250px;
							top:calc(50% - 180px);
							font:$slide-text-font;
							padding:80px 40px;
							p {
								font-size: 1.222rem;
							}
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:0;
		width:100%;
		left:0;
		@include media($narrow) {
			bottom:-23px;
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content {
				margin-bottom: 50px;
				.slick-list .slick-track .views-row {
					.card-slide-image img {
						left: auto;
						width: 100%;
						max-width: 100%; 
					}
					.card-slide-description {
						bottom:0;
						position: relative;
						width:100%;
						height: auto;
						border-radius: 0;
						top: auto;
						padding: 20px;
						left: 0;
						bottom: 0;
					}
				}
			}
		.view-footer {
			bottom: -50px!important;
			position:absolute;
			width:100%;
			left: auto;		
			}
		}
	}
}

//SLIDERS IN SIDEBAR
#sidebar-second {
	.view-slider .view-content {
		margin-bottom: 50px;
		.side-row.views-row   {
			.card-slide-description {
				bottom:0;
				position: relative;
				width:100%;
				height: auto;
				border-radius: 0;
				top: auto;
				padding: 20px;
				left: 0;
				bottom: 0;				
			}
			.view-footer {
				bottom: -50px!important;
				position:absolute;
				width:100%;
				left: auto; 
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		margin-bottom: 0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}



