// /* MAIN MENU */ //

$nav-base-font        	 	: 1.111rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: $primary;
$nav-base-padding        			: 0.625rem 1.5625rem;
$nav-base-hover-padding   		: 0.625rem 1.5625rem;
$nav-sub-base-padding					: 0.625rem 0.875rem;
$nav-sub-base-hover-padding		: 0.625rem 0.875rem;

$nav-base-padding-smaller 				: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.375rem 1.125rem;

$nav-active-bg-color      		: $star_command;
$nav-active-text-color    		: white;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $tangerine;
$nav-hover-text-color     		: $primary;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $winters_day;
$nav-highlight-text-color 		: $primary; 
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: $ghost_white;
$nav-sub-hover-bg-color   		: $tangerine;
$nav-sub-text-color       		: $primary;
$nav-sub-hover-text-color   	: $primary;
$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;
$nav-sub-active-text-color    : $star_command;

$nav-mob-text-color      						: $primary;
$nav-mob-active-text-color      		: $star_command;
$nav-mob-active-bg-color      			: $dark_lavender;

$nav-mob-1st-bg-color     					: $winters_day;
$nav-mob-1st-highlight-bg-color     : $stellar_light;
$nav-mob-1st-highlight-text-color   : $primary;
$nav-mob-1st-line-color     				: red;
$nav-mob-1st-border-color     			: $winters_day;

$nav-mob-2nd-bg-color     					: $ghost_white;
$nav-mob-2nd-highlight-bg-color     : $stellar_light;
$nav-mob-2nd-highlight-text-color   : $star_command;
$nav-mob-2nd-line-color     				: transparent;
$nav-mob-2nd-border-color     			: $glacier_point;

$nav-mob-3rd-bg-color     					: white;
$nav-mob-3rd-highlight-bg-color     : $winters_day;
$nav-mob-3rd-highlight-text-color   : $primary;
$nav-mob-3rd-line-color     				: green;
$nav-mob-3rd-border-color     			: $glacier_point;

$nav-mob-4th-bg-color     					: $azureish_white;
$nav-mob-4th-highlight-bg-color     : $blue_bell;
$nav-mob-4th-highlight-text-color   : $primary;
$nav-mob-4th-line-color     				: purple;
$nav-mob-4th-border-color     			: $dark_lavender;

$nav-mob-border											: 1px solid $glacier_point;
$nav-mob-expanded-border						: 1px solid $glacier_point;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $grey;
$nav-button-hover-bg-color			: $grey;
$nav-button-height      				: 100px;
$nav-button-width      					: 86px;
$nav-button-bar-bg-color      	: $carbon;
$nav-button-bar-hover-bg-color  : $carbon;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 8px;
