@media (min-width:$wide) {
	.no-sidebars .node-content .card-summary,
	.no-sidebars .node-content .card-intro,
	.no-sidebars .node-content .card-body,
	.no-sidebars .node-content .card-authors {
		padding:0 16% 0 0;
	}
}
.node-leaf {
  main {
    background-color: $main-page-bg;
  }
  #content {
    padding: $vert-space;
    background-color: white;
		overflow:hidden;
    
    .card-title-field h1 {
      font-size: 2rem;
      font-weight: normal;
    }
    .card-intro {
      margin-top: $vert-space;
    }
    .card-image {
      float: left;
      margin: 0 $vert-space $horz-space 0;
      
      @media(min-width:640px){
        max-width:55%;
      }
    }
  }
}

main section#content {
  .content-main,
  .content-additional {
    padding: 0;
    @include media(880px) {
      padding: 0 20px 0 0;
    }
  }
	.content-main + .content-additional {
		margin-top: $vert-space*1.5;
	}
}

.node-content {
	.card-label {
		margin-bottom:5px;
		width:100%;
	}
	h1 .card-title-field {
		font-size:2rem;
		line-height:1.1875;
		color:$primary;
		font-family:$title-font-family;
		margin:0 0 0;
		text-transform:none;
	}
	.card-image {
		margin-bottom:$vert-space;
	}
	.card-display-date {
		font-size:0.875rem;
		margin-right:3px;
		padding:0;
		display: inline-block;
		&:after {
			content:"|";
			margin: 0 5px;
			height:1em;
			display: inline;
		}
	}
	.card-location {
    display: inline;
	}
	.card-date,
	.card-display-date,
	.card-location,
	.card-date-display-override {
		margin-bottom:$vert-space*0.75;
		font-size: 0.875rem;
		color:$granite_grey;
	}
	.card-job-title {
		color:$secondary;
		font-size:1.125em;
	}
  .card-summary {
    color:$primary;
    font-size:1.25em;
    font-weight:bold;
    line-height:1.3;
  }
	&.node-organisation {
		.card-logo {
			text-align: center;
			background-color: transparent;
			margin-bottom:1rem;
			
			@media(min-width:$narrow) {
				float:right;
				margin-left:1.25rem;
				max-width:calc(50% - 0.625rem);
			}
		}
	}		
}

.node-type-publication {
	
	#sidebar-second  {
		.block-views {
			background: $side-row-bg;
    	border-radius: 3px;
    	box-shadow: $box-shadow;
    	margin: 0 0 25px;
    	padding: 20px 20px;
    	overflow: hidden;
			h2 {
				font:$side-row-title-font;		   
				padding: 20px;
		    margin: -20px -20px 20px;
		    border-bottom: 1px solid $ultramarine;
		    text-align: center;
			}
		}
	}
	.card-journal,
	.card-citation-date,
	.card-volume,
	.card-pages {
		display: inline;
		
		div,
		div::after {
			display: inline;
		}
	}
	.card-source {
		margin-top:15px;
		font-weight: 600;
		.label {
			display:none;
		}
	}
	
	.card-abstract {
		margin-top:15px;
	}
	
	.card-journal {
		//font-weight: 600;
		font-size: 1rem;
		div::after {
			content:",";
			margin: 0 2px 0 0;	
		}
	}
	.card-citation-date,
	.card-year {
		font-weight: 600;
		div::after {
    	content: ";";
		}
	}
	.card-volume {
		div::after {
			content:":";
			margin: 0 0px 0 2px;	
		}
	}

	.card-doi {
		display:inline;
		&:before {
			content:".";
			display: inline;
			margin:0 2px 0 -3px;
		}
		.card-label:after {
			content:":";
			display: inline;
			margin: 0 2px;
		}
	}
	.card-doi,
	.card-pmid {
		margin-top: 5px;
		clear: left;
		
		.card-label {
			display: inline-block;
			font-weight: 600;
		}
	}
	
	.card-authors,
	.card-abbr-authors {
		font-style:italic;
		margin-bottom:15px;
	}
	
	.card-link {
		margin-bottom: 5px;
	}
}

.node-type-resource {
	.card-resource-type {
    border: none;
		padding: 0;
		margin-bottom:10px;
		font-size: 1em;
	}
	.card-link {
		margin-bottom:10px;
	}
	.card-published-by {
		font-size: 1em;
	}
}
.node-type-event {
	.card-link {
		margin:15px 0;
	}
}

.card-tag {
	margin-top: 15px;
}